<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App'
}
</script>

<style lang="scss">
  html,body,#app{
    height: 100%;
  }
  *{
    padding: 0px;
    margin: 0px;
  }
</style>
