import Vue from "vue";
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            component: ()=>import('./login/loginPage.vue')
        },
        {
            path: '/home',
            component: ()=>import('./home/homePage.vue')
        },
        {
            path: '/work',
            component: ()=>import('./home/workPage.vue')
        },
        {
            path: '/account',
            component: ()=>import('./home/accountPage.vue')
        },
        {
            path: '/hotel',
            component: ()=>import('./home/hotelPage.vue')
        }
    ]
})

export default router