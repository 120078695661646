import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './view/Router';
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import qs from 'qs'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueCookies);
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.prototype.qs = qs;
Vue.prototype.DOMAIN = "http://book.admins.gorym.com"

new Vue({
  router: router,
  render: h => h(App)
}).$mount('#app')
